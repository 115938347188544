<template>
  <div>
    <el-dialog :visible.sync="visible" title="检核">
      <el-form
        :model="form"
        :rules="rules"
        class="form"
        label-position="right"
        label-width="120px"
        ref="form"
      >
        <el-form-item label="检核意见" prop="check_memo">
          <el-input
            :autosize="{ minRows: 2, maxRows: 4}"
            placeholder="请输入内容"
            type="textarea"
            v-model="form.check_memo"
          ></el-input>
        </el-form-item>
      </el-form>
      <span class="dialog-footer" slot="footer">
        <el-button @click="reset">取 消</el-button>
        <el-button @click="submit" type="primary">完 成</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      visible: false,
      form: {
        trace_id: 0,
        check_memo: ''
      },
      rules: {
        check_memo: [{ required: true, message: '必需填写', trigger: 'blur' }]
      }
    }
  },
  methods: {
    show(id) {
      this.form.trace_id = id
      this.visible = true
    },
    reset() {
      this.form.id = 0
      this.form.check_memo = ''
      this.visible = false
    },
    submit() {
      this.$axios.post('/trace/check', this.form).then(res => {
        if (res.data.code == 200) {
          this.$message.success(res.data.msg)
          this.visible = false
          this.$parent.setCheck()
        } else {
          this.$message.error(res.data.msg)
        }
      })
    }
  }
}
</script>