<template>
  <el-form class="detail" label-position="right" label-width="100px">
    <el-card class="main-content" shadow="hover">
      <div class="jue-card-header">
        <div class="jue-card-title">跟踪明细</div>
      </div>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="客户姓名:">{{dataList.realname}}</el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="手机电话:">{{dataList.mobile}}</el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="审核状态:">{{dataList.confirm_status == 2 ? '未审核' : '无'}}</el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item
            label="销售顾问:"
            v-if="$store.state.userList[dataList.seller_uid] != undefined"
          >{{$store.state.userList[dataList.seller_uid][0]}}</el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="重点客户:">{{dataList.is_vip == '1' ? '是' : '否'}}</el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="预计购车日期:">{{dataList.expect_buy_date}}</el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="车型媒体价:">￥{{dataList.media_amount}}</el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="购置税:">￥{{dataList.purchase_tax_amount}}</el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="竞争品牌:">
            <span>{{dataList.contend_brand}}</span>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="竞争车型:">
            <span>{{dataList.contend_model}}</span>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="优惠折扣:">￥{{dataList.discount_amount}}</el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="购买精品金额:">￥{{dataList.buy_parts_amount}}</el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="客户级别:">
            <span
              v-if="dataList.client_level != '' && $store.state.cfgData[dataList.client_level] != undefined"
            >{{ (cfgData[dataList.client_level])[0] }}</span>
            <span v-else>-</span>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="购买精品明细:">{{dataList.buy_parts_items}}</el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="下次回访日期:">{{dataList.next_trace_date}}</el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item
            label="原销售顾问:"
            v-if="$store.state.userList[dataList.old_seller_uid] != undefined"
          >{{$store.state.userList[dataList.old_seller_uid][0]}}</el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="二手车折价:">￥{{dataList.used_car_amount}}</el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="赠送精品明细:">{{dataList.donate_parts_items}}</el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="邀约到店日期:">{{dataList.invite_income_date_begin}} / {{dataList.invite_income_date_end}}</el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="预估保费:">￥{{dataList.insurance_tax_amount}}</el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="跟踪类型:">
            <span
              v-if="dataList.trace_type != '' && $store.state.cfgData[dataList.trace_type] != undefined"
            >{{ (cfgData[dataList.trace_type])[0] }}</span>
            <span v-else>-</span>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="综合服务费:">￥{{dataList.service_tax_amount}}</el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="促进结果:">
            <span
              v-if="dataList.trace_status != '' && $store.state.cfgData[dataList.trace_status] != undefined"
            >{{ (cfgData[dataList.trace_status])[0] }}</span>
            <span v-else>-</span>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="车辆净价:">￥{{dataList.net_amount}}</el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="预计总费用:">￥{{dataList.tax_amount}}</el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="贷款方式:">
            <span
              v-if="dataList.credit_type != '' && $store.state.cfgData[dataList.credit_type] != undefined"
            >{{ (cfgData[dataList.credit_type])[0] }}</span>
            <span v-else>-</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="跟踪描述:">{{dataList.trace_memo}}</el-form-item>
        </el-col>
      </el-row>
      <el-divider>
        <i class="el-icon-mobile-phone"></i> 跟踪文件
      </el-divider>
      <el-row>
        <el-col :span="8">
          <a
            :href="item.url"
            :key="index"
            class="file-list"
            target="_blank"
            v-for="(item, index) in dataList.audio_file_list"
          >{{item.name}}</a>
        </el-col>
      </el-row>

      <!-- <el-row>
        <el-col :key="index" :span="8" v-for="(item, index) in dataList.audio_file_list">
          <player-audio :audio-src="item.url" :controls="true">j</player-audio>
        </el-col>
      </el-row>-->
    </el-card>

    <el-card class="main-content" shadow="hover">
      <div class="jue-card-header">
        <div class="jue-card-title">贷款明细</div>
      </div>
      <el-row :gutter="20">
        <el-col :key="key" :span="6" v-for="(item, key) in JSON.parse(dataList.credit_detail)">
          <el-form-item :label="credit_detail[key]+':'">{{item}}</el-form-item>
        </el-col>
      </el-row>
    </el-card>

    <el-card class="main-content" shadow="hover">
      <div class="jue-card-header">
        <div class="jue-card-title">保险明细</div>
      </div>
      <el-row :gutter="20">
        <el-col :key="key" :span="6" v-for="(item, key) in JSON.parse(dataList.insurance_detail)">
          <el-form-item :label="insurance_detail[key]+':'" v-if="key == 'threePayment'">￥{{item}}</el-form-item>
          <el-form-item :label="insurance_detail[key]+':'" v-else>{{item == 1 ? '有' : '无'}}</el-form-item>
        </el-col>
      </el-row>
    </el-card>

    <el-card class="main-content" shadow="hover">
      <div class="jue-card-header">
        <div class="jue-card-title">其它明细</div>
      </div>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="订车日期:">{{dataList.book_date}}</el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="交车日期:">{{dataList.deliver_date}}</el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="退订日期:">{{dataList.unbook_date}}</el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="失控日期:">{{dataList.lose_date}}</el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="战败品牌:">{{dataList.fall_brand}}</el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="战败车型:">{{dataList.fall_model}}</el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="战败说明:">{{dataList.fall_memo}}</el-form-item>
        </el-col>
      </el-row>
    </el-card>

    <el-card class="main-content" shadow="hover" v-if="dataList.custom_data != '{}'">
      <div class="jue-card-header">
        <div class="jue-card-title">其它内容</div>
      </div>
      <el-row :gutter="20">
        <el-col :key="key" :span="6" v-for="(item, key) in JSON.parse(dataList.custom_data)">
          <el-form-item :label="getFieldKey(key)+':'">{{item}}</el-form-item>
        </el-col>
      </el-row>
    </el-card>

    <el-card class="main-content" shadow="hover">
      <div class="jue-card-header">
        <div class="jue-card-title">检核日志</div>
      </div>
      <el-row :gutter="20" :key="index" v-for="(item, index) in dataList.check_log">
        <el-col :span="6">
          <el-form-item label="检查人:">{{$store.state.userList[item.create_uid][0]}}</el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="检查时间:">{{item.check_date}}</el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="检查意见:">{{item.check_memo}}</el-form-item>
        </el-col>
      </el-row>
    </el-card>
  </el-form>
</template>
<script>
// import PlayerAudio from 'vue-player-audio'
export default {
  props: ['trace_id'],
  components: {
    // PlayerAudio
  },
  data() {
    return {
      credit_detail: {
        loan: '贷款金额',
        downPayment: '首付金额',
        monthlyPayment: '月供金额',
        interest: '利息金额'
        // cost1: '银行审核费',
        // cost2: '货款审核费',
        // cost3: '车辆抵押费'
      },
      insurance_detail: {
        three: '三责',
        threePayment: '三责金额',
        insurance1: '交强险',
        insurance2: '车损险',
        // insurance3: '不计免赔',
        insurance4: '划痕险',
        insurance5: '盗抢险',
        // insurance6: '玻璃险',
        insurance7: '意外伤害险',
        insurance8: '座位险',
        insurance9: '随车行李险',
        // insurance10: '自然险'
      },
      cfgfield: {},
      cfgData: {},
      dataList: {
        custom_data: '{}',
        insurance_detail: '{}',
        credit_detail: '{}'
      }
    }
  },
  mounted() {
    this.getCfgData()
    this.getDataList()
  },
  methods: {
    //返回自定义片段的键值
    getFieldKey(str) {
      let id = str.replace(/field_/g, '')
      return this.cfgfield[id]
    },
    getDataList() {
      this.$axios
        .post('/trace/detail', {
          trace_id: this.trace_id
        })
        .then(res => {
          this.dataList = res.data.data
          this.$parent.client_id = res.data.data.client_id
          this.$parent.setCheck(res.data.data.check_status)
        })
    },
    //获取基础数据信息
    getCfgData() {
      this.$axios.post('/index/cfgdata').then(res => {
        this.cfgData = res.data.data.list
      })

      this.$axios.post('/index/cfgfield').then(res => {
        this.cfgfield = res.data.data.list
      })
    }
  }
}
</script>
<style lang="less" scoped>
.file-list {
  display: block;
  padding: 3px;
}
</style>