<template>
  <div class="main" data-title="意向客户登记" v-title>
    <div class="page-header">
      <div class="page-header-body">
        <h2 class="page-header-title">跟踪明细</h2>
        <div class="page-header-desc"></div>
      </div>
    </div>

    <track-detail :trace_id="trace_id"></track-detail>
    <div class="form-footer" v-has="'trace-check_log'">
      <el-button @click="showCheck()" type="primary" :disabled="checkStatus == 1">检核</el-button>
      <el-button @click="$router.go(-1)">返回</el-button>
    </div>

    <track-check :trace_id="trace_id" ref="check"></track-check>
  </div>
</template>
<script>
import TrackDetail from '@/components/TrackDetail.vue'
import TrackCheck from '@/components/customer/TrackCheck.vue'

export default {
  components: {
    TrackDetail,
    TrackCheck
  },
  data() {
    return {
      trace_id: this.$route.query.trace_id,
      checkStatus: 2,
      checkLog: []
    }
  },
  methods: {
    showCheck() {
      this.$refs.check.show(this.$route.query.trace_id)
    },
    setCheck(status) {
      this.checkStatus = status
    }
  }
}
</script>
<style lang="less" scoped>
.form-footer {
  padding: 0 20px;
  text-align: right;
}
</style>